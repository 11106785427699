* {
    --dark_green: #023020;
    --cream: #f5fff9;
    --logo_green: #9eb52d;

}
html {
    background-color: #f5fff9;
}
.App {
    min-width: 100vw;
    min-height:1000px;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position:center center;
    background-color:var(--cream);
  }
/* .home_body {
    background-color: var(--dark_green);
} */
  /* Hero picture CSS */
  .hero {
    width: 100%;
    height: auto;
    border-top: 7px solid var(--dark_green);
  }
.title {
    font-size: 20px;
    color: white;

    /* header settings */
}
.headerContact {
    background-color: var(--dark_green);
    position:fixed;
    z-index: 1030;
    width: 100%;
}
.headerContact a {
    color:var(--cream);
    text-decoration: none;
}
.headerContact a:hover {
    color:var(--logo_green);
    text-decoration: none;
    transition: 0.25s;
}

.header{
    background-color: var(--cream);
    color:white;
    font-size: 15px;
    text-decoration: none;
    border-bottom: 7px solid var(--dark_green);
}
a {
    text-decoration: none;
}

/* logo settings */
.logo_container {
    width: 500px; /* Or your desired size */
    height: 200px; /* Adjust this based on your design */
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
        margin: 0 auto; /* Center the container without adding extra space */
        
}
.logo {
    margin-top: 80px;
    width: 100%; /* Ensure it scales within the container */ /* Maintain aspect ratio */
    height: auto;
}
/* footer settings */

.footer{
    width: 100%;
    height: 100px;
    background-color: #023020;
    margin-top: auto;
    text-align: center;
    width: 100%;
}
.footer-icon{
padding: 0 40px 0px 40px;
color: white;
font-size:35px;
background-color: #023020;
margin: 10px 10px;
}
.footer-icon:hover{
color: var(--logo_green);
transition: .25s;
    }
.footer-icon i {
        padding-top: 30px;
    }
.about-text {
    padding: 0 200px 0 200px
}

/* Navigation settings */

.navbar {
    background-color: var(--cream);
    
    
}
.navibar {
    justify-content:space-between;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

}


.navilink {
 
        display: block;
        padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
        font-size: var(--bs-nav-link-font-size);
        font-weight: var(--bs-nav-link-font-weight);
        color: var(--logo_green);
        text-decoration: none;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

}
.navbar a {
    color: var(--dark_green)
}
.navbar a:hover {
    color: var(--logo_green)
}
 .active {
    color: var(--logo_green) !important
}
.menu-icon{
    color:white;
}
/* Card styling */
.cardTitle {
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 8px;
    color: white
  }
  .card-body {
    display: flex;
    flex-direction: column;
  }
  .card-text {
    font-size: 1em;
    line-height: 1.5;
    color: white;
    width: 90%;
  }
.cardImg {
    width: 400px;               /* Sets a fixed width for the image */
    height: 200px;               /* Keeps the image's aspect ratio */
    margin-right: 20px;         /* Adds space between image and text */
    border-radius: 4px 4px;         /* Rounds image corners slightly */   
    padding-right: 10px;
  }
.card-container {
    display: flex;               /* Enables horizontal layout */
    align-items: column;     /* Aligns image and text top-aligned */
    border-radius: 20px;
    padding: 20px;
    margin: 10px ;
    width: 98%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #023020;
    border: white 3px solid;
  }

  /* form CSS */
.input_text {
width: 100%;
}
.no-bold  {
    font-weight:300;
}


  .navbar-toggler:focus {
    box-shadow: none !important;  /* Remove focus outline or shadow */
    border: none !important;      /* Remove border on focus */
  }
    /* About section CSS */

    .text_list {
        margin-left: 15%;  /* Push the list to the right */
        margin-right: 0;    /* Ensure it doesn't have space on the right */
        width: fit-content; /* Ensure it doesn't take up more space than needed */
        padding-left: 0;    /* Remove the default padding */
        padding: 40px 0 40px 0;

    }
    .remote_text_list {
        margin-left: 15%;  /* Push the list to the right */
        margin-right: 0;    /* Ensure it doesn't have space on the right */
        width: fit-content; /* Ensure it doesn't take up more space than needed */
        padding-left: 0;    /* Remove the default padding */
        padding: 40px 0 40px 0;

    }
   .remote_text_list li {
padding-top: 15px;

    }

    .remote_text_list li {
font-size: 20px;

    }
.list_title {
    font-size: 25px !important;
}
.text_container {
  width: 75%;
  margin: 0 auto;
  line-height: 2.0;
  opacity: 0;             /* Initially hide the element */
  visibility: hidden;     /* Initially set visibility to hidden */
  transition: opacity 1.5s ease, visibility 1s ease;  /* Apply a transition */
}

.text_container.show {
  opacity: 1.5;             /* Make the element visible */
  visibility: visible;    /* Set visibility to visible */
}
.contact_container {
    width: 85%;
    margin: 0 auto;
    line-height: 2.5;
    opacity: 0;             /* Initially hide the element */
    visibility: hidden;     /* Initially set visibility to hidden */
    transition: opacity 1.5s ease, visibility 1s ease;  /* Apply a transition */
}
.contact_container.show {
    opacity: 1.5;             /* Make the element visible */
    visibility: visible;    /* Set visibility to visible */
}


 .hidden {
    opacity: 0;
    transform: translateY(20px);  /* Slide the element down */
    transition: opacity 2s ease, transform 2s ease;
  }
    /* contact form */

    .page_title {
font-size: 60px;
width: 100%;
    }

    .about {
        background-color: #f5fff9;
    }
  /* Visible state (after the transition) */
  .visible {
    opacity: 1;
    transform: translateY(0);
  }

  .language_lists {
    padding-top: 40px;
    display: flex;
    justify-content: space-between; /* Adds space between the lists */
    gap: 2rem; /* Adds a gap between the lists */
    flex-wrap: wrap; /* Ensures lists wrap on smaller screens */
  }
  
  .language_list {
    padding: 0; /* Removes default padding */
    margin: 0; /* Removes default margin */
    width: 45%; /* Each list takes 45% of the container width */
  }
  
  .language_list li {
    margin-bottom: 0.5rem; /* Adds spacing between list items */
  }

  .list_title {
    list-style-type: none;
  }


@media screen and (max-width: 788px) {
.thank_you {
padding-top: 100px;
font-size: 20px;
text-align: justify;
}


    .about_text {
font-size: 12px;
text-align: justify;

}
    .language_list {
        width: 100%; /* Each list takes full width */
      }
/* Mobile Card styling */
.container {
    margin: 0 !important;
    padding: 0 !important;
}
.cardTitle {
    font-weight: bold;
    font-size: 1.5em;
    margin-bottom: 8px;
    color: white
  }
  .card-body {
    display: flex;
    flex-direction: column;
  }
  .card-text {
    font-size: 1em;
    line-height: 1.5;
    color: white;
    width: 100%;
    display: block;
  }
.cardImg {
    width: 100%;               /* Sets a fixed width for the image */
    height: auto;    
    border-radius: 5px;           /* Keeps the image's aspect ratio */
    margin-bottom: 20px;         /* Adds space between image and text */
    display: block;
    padding-right: 0;
    padding: 20px 20px;
  }
.card-container {
    display: flex; 
    flex-direction: column;              /* Enables horizontal layout */
    align-items: flex-start;     /* Aligns image and text top-aligned */
    width: 100%;
    background-color: #023020;
    border-radius: 0;
    margin: 20px 0;
    
  }


    .App {
        max-height: 100vh;
    }
    /* Mobile Footer Settings */
    .footer{
        width: 100%;
        height: 80px;
        background-color: #023020;
        padding-bottom: 20px;
    }
    .footer-icon{
    margin-left: -15px;
        color: white;
        font-size:20px;
        background-color: #023020;
        }

            /* Mobile Header and Logo Settings */
    .headerContact {
        background-color: #023020;
        position:fixed;
        z-index: 1030;
        width: 100%;
        font-size: 10px;
        text-decoration: none;
    }
    .logo_container {
        width:auto ;              /* Or your desired size */
        height: 200px;             /* Adjust based on your design */
        display: flex;            /* Use flexbox layout */
        justify-content: center;  /* Horizontally center the logo */
        align-items: center;      /* Vertically center the logo */
    }
    
    .logo {
        width: 100%;              /* Ensure it scales within the container */
        height: auto;             /* Maintain the aspect ratio */
        margin-left: -25px;
    }
    .menu-icon{
        visibility: visible;
    }
    .navibar {
        padding: 0;
    }
    .navbar {
         width: 100vw;
         align-items: center;
         margin: 0;
    }
    .about-text{
        padding: 0 20px 25px 20px;
    }
    .title{
        text-align: center;
    }
    
    .page_title {
        font-size: 30px;
        
            }

} 